<template>
  <el-row class="table">
    <span class="title">教师课酬统计列表</span>
    <el-row class="row-bg">

      <el-select
        v-model="periodVal"
        multiple
        filterable
        placeholder="请选择统计周期"
        class="tb-select"
      >
        <el-option
          v-for="item in periodList"
          :key="item.id"
          :label="item.startTime.substring(0, 10) + '~' + item.endTime.substring(0, 10)"
          :value="item.id"
        ></el-option>
      </el-select>

      <el-input
        v-model="employeeName"
        class="tb-sm-input"
        placeholder="姓名/工号"
        @keyup.enter.native="search"
      ></el-input>

        <el-button type="primary" size="small" @click="search" class="tb-button">查询</el-button>
        <el-button type="info" size="small" @click="reset" class="tb-button">重置</el-button>
        <download-excel :data="statRes" :fields="json_fields" name="教师课酬统计结果.xls" class="inline-block">
          <el-button
            type="primary"
            size="small"
            class="tb-button"
            title="若想多出所有数据，请讲每页条数设置为最大"
          >导出到Excel</el-button>
        </download-excel>
        <el-button type="info" size="small" @click="addStat" class="tb-button">新增统计</el-button>
    </el-row>
    <el-row class="row-bg pr-15">
      <el-col :span="24">
        <el-table
          stripe
          :data="statRes"
          border
          highlight-current-row
          size="mini"
          empty-text="暂无符合查询条件的数据"
          style="width: 100%;font-size: 10px"
          @selection-change="handleSelectionChange"
        >
          <el-table-column fixed prop="statDateRange" label="统计周期" align="center" width="250px"></el-table-column>
          <el-table-column prop="loginName" label="工号" align="center"></el-table-column>
          <el-table-column fixed prop="teacherName" label="员工姓名" align="center"></el-table-column>
          <!-- <el-table-column fixed prop="courseTitle" label="课程名" align="center"></el-table-column> -->
          <el-table-column prop="baseDurationPay1" label="讲师-基本课时费" align="center"></el-table-column>
          <el-table-column prop="courseDurationPay1" label="讲师-课系费" align="center"></el-table-column>
          <el-table-column prop="classMemberDurationPay" label="讲师-班容费" align="center"></el-table-column>
          <el-table-column prop="total1" label="（小计）讲师课时费" align="center"></el-table-column>
          <el-table-column prop="baseDurationPay2" label="助教-基本课时费" align="center"></el-table-column>
          <el-table-column prop="courseDurationPay2" label="助教-课系费" align="center"></el-table-column>
          <el-table-column prop="total2" label="（小计）助教课时费" align="center"></el-table-column>
          <el-table-column prop="totalPay" label="总课时费" align="center"></el-table-column>
          <el-table-column prop="duration1" label="讲师课时" align="center"></el-table-column>
          <el-table-column prop="duration2" label="助教课时" align="center"></el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.currentPage"
          :page-sizes="pagination.pageSizes"
          :page-size="pagination.pageSize"
          :layout="pagination.layout"
          :total="pagination.total"
        ></el-pagination>
      </el-col>
    </el-row>

    <!-- salaryAgain -->
    <salary-again :show="salaryDialog" @close="salaryDialog=false"></salary-again>
  </el-row>
</template>

<script>
import JsonExcel from 'vue-json-excel'
import { getListTeacherLessonPayStat, getListPeriod } from '@/api/statistics'
import salaryAgain from '@/components/statistics/salary-again'

export default {
  components: { downloadExcel: JsonExcel, salaryAgain },
  data () {
    return {
      json_fields: {
        '统计周期': 'statDateRange',
        '员工姓名': 'teacherName',
        '工号': 'loginName',
        // "课程名": 'courseTitle',
        '讲师-基本课时费': 'baseDurationPay1',
        '讲师-课系费': 'courseDurationPay1',
        '讲师-班容费': 'classMemberDurationPay',
        '（小计）讲师课时费': 'total1',
        '助教-课系费': 'courseDurationPay2',
        '（小计）助教课时费': 'total2',
        '总课时费': 'totalPay',
        '讲师课时': 'duration1',
        '助教课时': 'duration2'
      },
      multipleSelection: [],
      periodVal: [],
      // 检索条件
      employeeName: null,
      minStartTime: null,
      maxStartTime: null,
      periodList: [],
      salaryDialog: false,
      // 日期选择器配置
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      statRes: [], // 查询结果
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 500, 1000],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      }
    }
  },
  created () {
    this.getListPeriod()
  },
  methods: {
    async getListPeriod () {
      const res = await getListPeriod({
        pageNum: 1,
        pageSize: 1000
      })
      this.periodList = res.body.list
    },
    reset () {
      this.courseIdSet = []
      this.periodVal = null
      this.employeeName = null
    },
    /**
     * 教师排课统计列表
     */
    listTeacherLessonPayStat () {
      if (this.periodVal.length !== 0) {
        getListTeacherLessonPayStat({
          periodIdSet: this.periodVal,
          employeeName: this.employeeName,
          needCount: true,
          pageNum: this.pagination.currentPage,
          pageSize: this.pagination.pageSize
        }).then(res => {
          if (res.state === 'success') {
            let statRes = res.body.list
            statRes.forEach(function (v, k, arr) {
              if (!v.teacherName) {
                v.teacherName = '--'
                v.loginName = '--'
              }
              v.statDateRange =
              v.startTime.substring(0, 10) + '至' + v.endTime.substring(0, 10)
              v.baseDurationPay1 = window.formatMoney(v.baseDurationPay1)
              v.baseDurationPay2 = window.formatMoney(v.baseDurationPay2)
              v.courseDurationPay1 = window.formatMoney(v.courseDurationPay1)
              v.courseDurationPay2 = window.formatMoney(v.courseDurationPay2)
              v.classMemberDurationPay = window.formatMoney(v.classMemberDurationPay)
              v.totalPay = window.formatMoney(v.totalPay)
              if (!v.duration1) {
                v.duration1 = 0
              }
              v.duration1 = Math.round((v.duration1 / 60) * 100) / 100
              if (!v.duration2) {
                v.duration2 = 0
              }
              v.duration2 = Math.round((v.duration2 / 60) * 100) / 100
            })
            this.statRes = statRes
            this.dataList = res.body.list
            this.pagination.total = res.body.total
          }
        })
      } else {
        window.$msg('请选择日期', 2)
      }
    },

    // 分页
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.listTeacherLessonPayStat()
    },
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.listTeacherLessonPayStat()
    },

    search () {
      this.pagination.currentPage = 1
      this.listTeacherLessonPayStat()
    },

    handleSelectionChange (val) {
      this.multipleSelection = val
    },

    // 新增统计
    addStat () {
      this.salaryDialog = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/table.scss";
</style>
