var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        width: "50%",
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", [_vm._v("新增统计")])]
      ),
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "150px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "选择统计周期：", prop: "periodVal" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "50%" },
                  attrs: {
                    multiple: "",
                    filterable: "",
                    placeholder: "请选择统计周期",
                  },
                  model: {
                    value: _vm.formData.periodVal,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "periodVal", $$v)
                    },
                    expression: "formData.periodVal",
                  },
                },
                _vm._l(_vm.periodList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label:
                        item.startTime.substring(0, 10) +
                        "~" +
                        item.endTime.substring(0, 10),
                      value: item.id,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-row",
            { staticStyle: { "text-align": "right", "margin-bottom": "15px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveStatistics },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                { attrs: { type: "info" }, on: { click: _vm.close } },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }