<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>新增统计</span>
    </div>
    <el-form ref="formData" :model="formData" :rules="rules" label-width="150px">
      <el-form-item label="选择统计周期：" prop="periodVal">
        <el-select
          v-model="formData.periodVal"
          multiple
          filterable
          placeholder="请选择统计周期"
          style="width: 50%;"
        >
          <el-option
            v-for="item in periodList"
            :key="item.id"
            :label="item.startTime.substring(0, 10) + '~' + item.endTime.substring(0, 10)"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-row slot="footer">
      <el-row style="text-align: right;margin-bottom: 15px;">
        <el-button type="primary" @click="saveStatistics">确 定</el-button>
        <el-button @click="close" type="info">取 消</el-button>
      </el-row>
    </el-row>
  </el-dialog>
</template>

<script>
import { getListPeriod, setStatTeacherSalary } from '@/api/statistics'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      periodList: [],
      formData: {
        periodVal: []
      },
      rules: {
        periodVal: [
          { required: true, message: '请选择统计日期', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    openDialog () {
      this.getListPeriod()
    },

    async getListPeriod () {
      const res = await getListPeriod({
        pageNum: 1,
        pageSize: 1000,
        needCount: true
      })
      this.periodList = res.body.list
    },

    forceUpdate () {
      this.$forceUpdate()
    },

    saveStatistics () {
      this.$refs.formData.validate(async valid => {
        if (valid) {
          await setStatTeacherSalary(JSON.stringify(this.formData.periodVal))
          window.$msg('统计成功')
          this.close()
        }
      })
    },

    close () {
      this.$emit('close')
    }
  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
</style>
