var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c("span", { staticClass: "title" }, [_vm._v("教师课酬统计列表")]),
      _c(
        "el-row",
        { staticClass: "row-bg" },
        [
          _c(
            "el-select",
            {
              staticClass: "tb-select",
              attrs: {
                multiple: "",
                filterable: "",
                placeholder: "请选择统计周期",
              },
              model: {
                value: _vm.periodVal,
                callback: function ($$v) {
                  _vm.periodVal = $$v
                },
                expression: "periodVal",
              },
            },
            _vm._l(_vm.periodList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: {
                  label:
                    item.startTime.substring(0, 10) +
                    "~" +
                    item.endTime.substring(0, 10),
                  value: item.id,
                },
              })
            }),
            1
          ),
          _c("el-input", {
            staticClass: "tb-sm-input",
            attrs: { placeholder: "姓名/工号" },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.search.apply(null, arguments)
              },
            },
            model: {
              value: _vm.employeeName,
              callback: function ($$v) {
                _vm.employeeName = $$v
              },
              expression: "employeeName",
            },
          }),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.search },
            },
            [_vm._v("查询")]
          ),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "info", size: "small" },
              on: { click: _vm.reset },
            },
            [_vm._v("重置")]
          ),
          _c(
            "download-excel",
            {
              staticClass: "inline-block",
              attrs: {
                data: _vm.statRes,
                fields: _vm.json_fields,
                name: "教师课酬统计结果.xls",
              },
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "tb-button",
                  attrs: {
                    type: "primary",
                    size: "small",
                    title: "若想多出所有数据，请讲每页条数设置为最大",
                  },
                },
                [_vm._v("导出到Excel")]
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "info", size: "small" },
              on: { click: _vm.addStat },
            },
            [_vm._v("新增统计")]
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "row-bg pr-15" },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%", "font-size": "10px" },
                  attrs: {
                    stripe: "",
                    data: _vm.statRes,
                    border: "",
                    "highlight-current-row": "",
                    size: "mini",
                    "empty-text": "暂无符合查询条件的数据",
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      fixed: "",
                      prop: "statDateRange",
                      label: "统计周期",
                      align: "center",
                      width: "250px",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "loginName",
                      label: "工号",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      fixed: "",
                      prop: "teacherName",
                      label: "员工姓名",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "baseDurationPay1",
                      label: "讲师-基本课时费",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "courseDurationPay1",
                      label: "讲师-课系费",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "classMemberDurationPay",
                      label: "讲师-班容费",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "total1",
                      label: "（小计）讲师课时费",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "baseDurationPay2",
                      label: "助教-基本课时费",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "courseDurationPay2",
                      label: "助教-课系费",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "total2",
                      label: "（小计）助教课时费",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "totalPay",
                      label: "总课时费",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "duration1",
                      label: "讲师课时",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "duration2",
                      label: "助教课时",
                      align: "center",
                    },
                  }),
                ],
                1
              ),
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.pagination.currentPage,
                  "page-sizes": _vm.pagination.pageSizes,
                  "page-size": _vm.pagination.pageSize,
                  layout: _vm.pagination.layout,
                  total: _vm.pagination.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("salary-again", {
        attrs: { show: _vm.salaryDialog },
        on: {
          close: function ($event) {
            _vm.salaryDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }